import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/personal-site/personal-site/src/components/Article.js";
import CodeSandbox from "components/CodeSandbox";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/reducer-patterns/part-1/"
        }}>{`Part 1: Introduction to `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`useReducer`}</code>{` and basic reducer patterns`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "/articles/reducer-patterns/part-1/"
      }}>{`In part 1`}</a>{`, we looked at reducer patterns which didn’t rely on any
params, or just the current state to calculate the next state. We also learned
that `}<mark>{`the primary job of a reducer is to produce a new state`}</mark>{`.`}</p>
    <p>{`Let’s continue to explore other reducer patterns.`}</p>
    <h2 {...{
      "id": "table-of-contents",
      "style": {
        "position": "relative"
      }
    }}>{`Table of Contents`}<a parentName="h2" {...{
        "href": "#table-of-contents",
        "aria-label": "table of contents permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <div {...{
      "className": "toc"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#reducer-using-action"
          }}>{`Reducer Using `}<code parentName="a" {...{
              "className": "language-text"
            }}>{`action`}</code></a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-both-currentstate-and-action-params"
            }}>{`Use Both `}<code parentName="a" {...{
                "className": "language-text"
              }}>{`currentState`}</code>{` and `}<code parentName="a" {...{
                "className": "language-text"
              }}>{`action`}</code>{` Params`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-action-param-only"
            }}>{`Use `}<code parentName="a" {...{
                "className": "language-text"
              }}>{`action`}</code>{` Param Only`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#implement-a-simple-usestate"
          }}>{`Implement a Simple `}<code parentName="a" {...{
              "className": "language-text"
            }}>{`useState`}</code></a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#implement-a-state-updater"
          }}>{`Implement a State Updater`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#dispatch-functions"
          }}>{`Dispatch Functions`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#takeaways"
          }}>{`Takeaways`}</a></p>
      </li>
    </ul>
    <undefined></undefined>
    <h2 {...{
      "id": "reducer-using-action",
      "style": {
        "position": "relative"
      }
    }}>{`Reducer Using `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`action`}</code><a parentName="h2" {...{
        "href": "#reducer-using-action",
        "aria-label": "reducer using action permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><img parentName="p" {...{
        "src": "/21180075d75a6e961abf29165b772f99/dispatch-flow.svg",
        "alt": "Sequence diagram of React.useReducer dispatch flow"
      }}></img></p>
    <p>{`Remember that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`(currentState, action) => newState`}</code>{` is the complete reducer
signature. In patterns we’ve explored so far, we haven’t used the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`action`}</code>{`
parameter. We know the hook automatically supplies the current state to the
reducer, but how does a reducer receive its `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`action`}</code>{` parameter? Via the
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dispatch`}</code>{` function we get when we create the hook:
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[state, dispatch] = useReducer(reducer)`}</code>{`. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dispatch`}</code>{` function takes a
single optional param: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dispatch(action)`}</code>{`.`}</p>
    <h3 {...{
      "id": "use-both-currentstate-and-action-params",
      "style": {
        "position": "relative"
      }
    }}>{`Use Both `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`currentState`}</code>{` and `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`action`}</code>{` Params`}<a parentName="h3" {...{
        "href": "#use-both-currentstate-and-action-params",
        "aria-label": "use both currentstate and action params permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Let’s look at an example where we use both params with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{` function.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`reducer`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`count`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` valueToAdd`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` count `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`+`}</span>{` valueToAdd`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` addToCount`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`useReducer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`div`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{`Count`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`div`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form
      onSubmit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`preventDefault`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` valueToAdd `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`currentTarget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`numberToAdd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token function"
            }}>{`addToCount`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`valueToAdd`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        Add to count`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`" "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
          name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"numberToAdd"`}</span>{`
          type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"number"`}</span>{`
          defaultValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"4em"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Add`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`What’s happening? When we submit the form, we dispatch (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`addToCount`}</code>{`) an action
(`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<input name="numberToAdd">`}</code>{`’s value). The reducer takes the current state
(`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`count`}</code>{`) and the action (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`valueToAdd`}</code>{`) to produce a new state
(`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`count + valueToAdd`}</code>{`).`}</p>
    <p>{`Many examples you’ve seen of actions take the shape of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{ type, payload }`}</code>{`.
While that’s the convention, `}<mark>{`an action can be anything you like`}</mark>{`
(including `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`undefined`}</code>{`). In this example, an action is simply a number.`}</p>
    <CodeSandbox as="h4" sandbox="reducer-pattern-021-x732b" title="reducer with state and action params" mdxType="CodeSandbox" />
    <h3 {...{
      "id": "use-action-param-only",
      "style": {
        "position": "relative"
      }
    }}>{`Use `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`action`}</code>{` Param Only`}<a parentName="h3" {...{
        "href": "#use-action-param-only",
        "aria-label": "use action param only permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Just like how we can choose to only use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`currentState`}</code>{` param in a reducer,
we can choose to only use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`action`}</code>{` param.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`reducer`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`_`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` newCount`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` newCount`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` setCount`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`useReducer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Count`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`count`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form
      onSubmit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`preventDefault`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` valueToAdd `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`currentTarget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`numberToAdd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token function"
            }}>{`setCount`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`count `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`+`}</span>{` valueToAdd`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`By switching up a few lines, our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{` now only relies on the action
(`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`newCount`}</code>{`) to calculate its new state.`}</p>
    <p>{`🤔 Wait a minute, that looks a lot like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`React.useState`}</code>{`.`}</p>
    <CodeSandbox as="h4" sandbox="reducer-pattern-02-ohxdv" title="reducer with action param only" mdxType="CodeSandbox" />
    <h2 {...{
      "id": "implement-a-simple-usestate",
      "style": {
        "position": "relative"
      }
    }}>{`Implement a Simple `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`useState`}</code><a parentName="h2" {...{
        "href": "#implement-a-simple-usestate",
        "aria-label": "implement a simple usestate permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Replace`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`reducer`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`_`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` newCount`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` newCount`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`count`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` setCount`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`useReducer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reducer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`with`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`reducer`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`_`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` newState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` newState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`useState`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`initialState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`useReducer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reducer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` initialState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`count`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` setCount`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`We have ourselves a simple `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{`! You can see that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`React.useState`}</code>{` is
syntactic sugar for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`React.useReducer`}</code>{`, simplifying the common use case of
updating a single state value. A complete re-implementation of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{` is a
few more lines of code. `}<a parentName="p" {...{
        "href": "https://kentcdodds.com/blog/how-to-implement-usestate-with-usereducer"
      }}>{`See Kent C. Dodd’s “How to implement useState with
useReducer”`}</a>{` if you’re interested in an in-depth
explanation.`}</p>
    <CodeSandbox as="h3" sandbox="reducer-pattern-03-1keqr" title="useState implemented using useReducer" mdxType="CodeSandbox" />
    <h2 {...{
      "id": "implement-a-state-updater",
      "style": {
        "position": "relative"
      }
    }}>{`Implement a State Updater`}<a parentName="h2" {...{
        "href": "#implement-a-state-updater",
        "aria-label": "implement a state updater permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <h3 {...{
      "id": "use-case",
      "style": {
        "position": "relative"
      }
    }}>{`Use Case`}<a parentName="h3" {...{
        "href": "#use-case",
        "aria-label": "use case permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`We have a user profile form that allows them to change each entry value in the
state object.`}</p>
    <h3 {...{
      "id": "solution",
      "style": {
        "position": "relative"
      }
    }}>{`Solution`}<a parentName="h3" {...{
        "href": "#solution",
        "aria-label": "solution permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`One elegant solution is to use the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax"
      }}>{`object spread syntax`}</a>{` to create the
next state.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`reducer`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`info`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` updates`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`info`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`updates `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` initialValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Pat Doe"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  twitter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@pdough"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  email`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"pat@pdough.me"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  website`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://pdough.me"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`info`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` update`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`useReducer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` initialValue`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`pre`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`JSON`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`stringify`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`info`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`null`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`pre`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`Object`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`entries`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`info`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`map`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`key`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` value`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`label key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` display`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"block"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`" "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
            value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            onChange`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`e`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`update`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`key`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`currentTarget`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`value `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <CodeSandbox as="h4" sandbox="reducer-pattern-035-lysww" title="simple state updater" mdxType="CodeSandbox" />
    <h2 {...{
      "id": "dispatch-functions",
      "style": {
        "position": "relative"
      }
    }}>{`Dispatch Functions`}<a parentName="h2" {...{
        "href": "#dispatch-functions",
        "aria-label": "dispatch functions permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Wait, say what? 🤔`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`reducer`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`count`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` action`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`action`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` dispatch`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`useReducer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`add`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`dispatch`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`count`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` count `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`+`}</span>{` value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`subtract`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`dispatch`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`count`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` count `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`-`}</span>{` value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`div`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{`Count`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`div`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        Change count by`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`" "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
          name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"modifier"`}</span>{`
          type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"number"`}</span>{`
          defaultValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"4em"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"button"`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onClick`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`e`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`add`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`Number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`currentTarget`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`form`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`modifier`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        Add
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"button"`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onClick`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`e`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`subtract`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`Number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`currentTarget`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`form`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`modifier`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        Subtract
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Yup, as stated earlier, `}<mark>{`an action can be anything you like`}</mark>{`. In this
example, we’re dispatching callback functions! 🤯`}</p>
    <CodeSandbox as="h3" sandbox="reducer-pattern-022-1jpr0" title="dispatch functions" mdxType="CodeSandbox" />
    <h2 {...{
      "id": "takeaways",
      "style": {
        "position": "relative"
      }
    }}>{`Takeaways`}<a parentName="h2" {...{
        "href": "#takeaways",
        "aria-label": "takeaways permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">{`Dispatched actions can take any shape you want: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`undefined`}</code>{`, a value, an
object, or even a function.`}</li>
      <li parentName="ul">{`The `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`action`}</code>{` parameter is what’s commonly shared between the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dispatch()`}</code>{` and
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`reducer()`}</code>{` functions—it’s their private, internal contract.`}</li>
      <li parentName="ul">{`React.useState is syntactic sugar for one specific use case of
React.useReducer.`}</li>
    </ul>
    <h2 {...{
      "id": "intermission",
      "style": {
        "position": "relative"
      }
    }}>{`Intermission`}<a parentName="h2" {...{
        "href": "#intermission",
        "aria-label": "intermission permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You can implement a reducer in any way you like, as long as it fulfills the
contract of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`([currentState], [action]) => newState`}</code>{`. You have complete freedom
in deciding what an action looks like, and how you want to calculate the new
state.`}</p>
    <p>{`We’ve explored different ways of writing the reducer function, and we haven’t
even come across the familiar `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`switch`}</code>{` statement yet. Don’t worry, we’ll get to
that in `}<a parentName="p" {...{
        "href": "/articles/reducer-patterns/part-3/"
      }}>{`part 3`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/reducer-patterns/part-1/"
        }}>{`Part 1: Introduction to `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`useReducer`}</code>{` and basic reducer patterns`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/reducer-patterns/part-3/"
        }}>{`Part 3: Reducers with switch statements`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      